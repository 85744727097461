<template>
  <div class="header">
    <div class="banner other" v-show="activeHeader == 'other'">
      <div class="nav" :class="scrollTurn ? 'banner_fix' : ''">
        <div class="page_nav">
          <div>
            <img class="logo" src="@/assets/logo-2.png" />
          </div>
          <div v-for="(item, index) in list" :key="index" class="pointer select_none" @click="toRoute(item.type)" :class="item.route == route.name ? 'active': ''">{{item.title}}</div>
        </div>
        <div class="link_nav">
          <div @click="toLogin" class="login">登录</div>
          <div class="phone iconfont icon-dianhuatianchong">400-8039-718</div>
        </div>
      </div>
    </div>
    <!-- 背调流程 -->
    <div class="banner" v-show="activeHeader == 'flowPath'">
      <img class="img" src="@/assets/bg_31.png" />
      <div class="nav" :class="scrollTurn ? 'banner_fix' : ''">
        <div class="page_nav">
          <div>
            <img class="logo" src="@/assets/logo-2.png" />
          </div>
          <div v-for="(item, index) in list" :key="index" class="pointer select_none" @click="toRoute(item.type)" :class="item.route == route.name ? 'active': ''">{{item.title}}</div>
        </div>
        <div class="link_nav">
          <div @click="toLogin" class="login">登录</div>
          <div class="phone iconfont icon-dianhuatianchong">400-8039-718</div>
        </div>
      </div>
    </div>
    <!-- 竞业管理 -->
    <div class="banner" v-show="activeHeader == 'competitionAgree'">
      <img class="img" src="@/assets/bg_32.png" />
      <div class="nav" :class="scrollTurn ? 'banner_fix' : ''">
        <div class="page_nav">
          <div>
            <img class="logo" src="@/assets/logo-2.png" />
          </div>
          <div v-for="(item, index) in list" :key="index" class="pointer select_none" @click="toRoute(item.type)" :class="item.route == route.name ? 'active': ''">{{item.title}}</div>
        </div>
        <div class="link_nav">
          <div @click="toLogin" class="login">登录</div>
          <div class="phone iconfont icon-dianhuatianchong">400-8039-718</div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="banner" v-show="activeHeader == 'aboutUs'">
      <img class="img" src="@/assets/bg_33.png" />
      <div class="nav" :class="scrollTurn ? 'banner_fix' : ''">
        <div class="page_nav">
          <div>
            <img class="logo" src="@/assets/logo-2.png" />
          </div>
          <div v-for="(item, index) in list" :key="index" class="pointer select_none" @click="toRoute(item.type)" :class="item.route == route.name ? 'active': ''">{{item.title}}</div>
        </div>
        <div class="link_nav">
          <div @click="toLogin" class="login">登录</div>
          <div class="phone iconfont icon-dianhuatianchong">400-8039-718</div>
        </div>
      </div>
    </div>
    <!-- 背调内容 -->

    <div class="banner other" v-show="activeHeader == 'surveyDetails'">
      <div class="nav" :class="scrollTurn ? 'banner_fix' : ''">
        <div class="page_nav">
          <div>
            <img class="logo" src="@/assets/logo-2.png" />
          </div>
          <div v-for="(item, index) in list" :key="index" class="pointer select_none" @click="toRoute(item.type)" :class="item.route == route.name ? 'active': ''">{{item.title}}</div>
        </div>
        <div class="link_nav">
          <div @click="toLogin" class="login">登录</div>
          <div class="phone iconfont icon-dianhuatianchong">400-8039-718</div>
        </div>
      </div>
    </div>

    <div class="banner other" v-show="activeHeader == 'scheme'">
      <div class="nav" :class="scrollTurn ? 'banner_fix' : ''">
        <div class="page_nav">
          <div>
            <img class="logo" src="@/assets/logo-2.png" />
          </div>
          <div v-for="(item, index) in list" :key="index" class="pointer select_none" @click="toRoute(item.type)" :class="item.route == route.name ? 'active': ''">{{item.title}}</div>
        </div>
        <div class="link_nav">
          <div @click="toLogin" class="login">登录</div>
          <div class="phone iconfont icon-dianhuatianchong">400-8039-718</div>
        </div>
      </div>
    </div>

    <div class="banner other" v-show="activeHeader == 'apiServe'">
      <div class="nav" :class="scrollTurn ? 'banner_fix' : ''">
        <div class="page_nav">
          <div>
            <img class="logo" src="@/assets/logo-2.png" />
          </div>
          <div v-for="(item, index) in list" :key="index" class="pointer select_none" @click="toRoute(item.type)" :class="item.route == route.name ? 'active': ''">{{item.title}}</div>
        </div>
        <div class="link_nav">
          <div @click="toLogin" class="login">登录</div>
          <div class="phone iconfont icon-dianhuatianchong">400-8039-718</div>
        </div>
      </div>
    </div>

    <!-- 首页的banner -->
    <div class="banner" id="homeBanner" v-show="activeHeader== 'home'">
      <img class="img" src="@/assets/bg_22.jpg" />
      <div class="nav" :class="scrollTurn ? 'banner_fix' : ''">
        <div class="page_nav">
          <div>
            <img class="logo" src="@/assets/logo-2.png" />
          </div>
          <div v-for="(item, index) in list" :key="index" class="pointer select_none" @click="toRoute(item.type)" :class="item.route == route.name ? 'active': ''">{{item.title}}</div>
        </div>
        <div class="link_nav">
          <div @click="toLogin" class="login">登录</div>
          <div class="phone iconfont icon-dianhuatianchong">400-8039-718</div>
        </div>
      </div>
      <div class="exp_now" @click="toLogin">立即体验</div>
    </div>
  </div>
</template>
    
<script lang="ts" setup>
import { ref, getCurrentInstance, onMounted, toRefs, defineProps, defineEmits, watch, nextTick, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
// const emit = defineEmits(["updateScroll"]);
const emit = defineEmits<{
  (e: "updateScroll", value: string): void;
}>();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  activeRoute: {
    type: String,
    default: "home",
  },
  scrollTurn: {
    type: Boolean,
    default: false,
  },
});
let activeHeader = ref("home");
watch(
  () => props.activeRoute,
  (value) => {
    switch (value) {
      // case "home":
      case "scheme":
      case "flowPath":
      case "surveyDetails":
      case "apiServe":
      case "competitionAgree":
      case "aboutUs":
      case "other":
        activeHeader.value = value;
        break;

      default:
        activeHeader.value = "home";
        break;
    }
  },
  { immediate: true }
);
let list = [
  { title: "首页", type: 1, route: "home", path: "/home" },
  { title: "解决方案", type: 2, route: "scheme", path: "/scheme" },
  { title: "背调流程", type: 3, route: "flowPath", path: "/flowPath" },
  { title: "背调内容", type: 4, route: "surveyDetails", path: "/surveyDetails" },
  { title: "API服务", type: 5, route: "apiServe", path: "/apiServe" },
  { title: "竞业管理", type: 6, route: "competitionAgree", path: "/competitionAgree" },
  { title: "关于我们", type: 7, route: "aboutUs", path: "/aboutUs" },
];
// 路由跳转
const toRoute = (key) => {
  for (const i in list) {
    let item = list[i];
    if (key == item.type) {
      if (item.route == route.name) {
        return;
      }
      router.push(item.path);
      nextTick(() => {
        emit("updateScroll", "1");
      });
    }
  }
};
const toLogin = () => {
  let url=location.protocol+'//'+location.host+'/pc/index.html#/login'
  // location.href = "https://devyc.zecaimao.cn/pc/index.html#/login";
  location.href = url;
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  position: relative;
  .active {
    position: relative;
  }
  .active::before {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background: #63dbf5;
    position: absolute;
    bottom: -5px;
    left: calc(50% - 20px);
  }
  .exp_now {
    width: 180px;
    background: #63dbf5;
    font-size: 14px;
    color: #333;
    line-height: 30px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 50px;
    user-select: none;
    position: absolute;
    bottom: 35%;
    left: calc(50% - 90px);
  }

  .img {
    width: 100%;
  }

  .banner_fix {
    background-color: rgb(13 45 94 / 80%) !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
  }
  .nav {
    width: 100%;
    height: 102px;
    position: absolute;
    top: 0;
    z-index: 9999;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 15px;

    .page_nav {
      width: 75%;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 100px;
      }

      div {
        width: 110px;
      }

      .pointer:hover {
        color: #63dbf5;
      }
    }

    .link_nav {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      .login {
        width: 100px;
        background: #63dbf5;
        color: #333;
        line-height: 30px;
        border-radius: 8px;
        cursor: pointer;
        margin-right: 50px;
        user-select: none;
      }

      .login:hover {
        color: #fff;
      }

      .phone.icon-dianhuatianchong:before {
        margin-right: 5px;
      }
      .phone{
        white-space: nowrap;
      }
    }
  }
}
.other.banner {
  .nav {
    background: #0d2d5e;
  }
}
</style>
    