<template>
  <div class="footer sys_BgColor">
    <div class="content">
      <div class="nav">
        <div class="pointer select_none" @click="toRoute(1)">首页</div>
        <div>|</div>
        <div class="pointer select_none" @click="toRoute(2)">解决方案</div>
        <div>|</div>
        <div class="pointer select_none" @click="toRoute(3)">背调流程</div>
        <div>|</div>
        <div class="pointer select_none" @click="toRoute(4)">背调内容</div>
        <div>|</div>
        <div class="pointer select_none" @click="toRoute(5)">API服务</div>
        <div>|</div>
        <div class="pointer select_none" @click="toRoute(6)">竞业管理</div>
        <div>|</div>
        <div class="pointer select_none" @click="toRoute(7)">关于我们</div>
      </div>
      <div class="text">
        <div class="cont">
          <div class="iconfont icon-telephone">业务联系</div>
          <div>客服联系：400-8039-718</div>
          <div>销售联系：18028211516、18028912996</div>
          <div>市场合作：tom.huang@hrbgc.com</div>
        </div>
        <div class="cont">
          <div class="iconfont icon-shouhuodizhi">广东</div>
          <div>广东省东莞市南城街道元美西路8号1栋18楼</div>
          <div>广东优才信息科技有限公司</div>
        </div>
        <div class="code_img textCenter">
          <img src="@/assets/wxWork.jpg" />
          <div class="f_12">企业微信</div>
        </div>
        <div class="code_img textCenter">
          <img src="@/assets/qrCode.png" />
          <div class="f_12">优才小程序</div>
        </div>
      </div>
      <el-divider />
      <div class="textCenter f_12">
        <span>© Copyright 2020 广东优才信息科技有限公司</span>
        <a class="pointer textLink marginL10" href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备2023002766号-1</a>
      </div>
      
    </div>
  </div>
</template>
<script lang="ts" setup>
import { getCurrentInstance, ref, reactive, defineProps, defineEmits, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
const emit = defineEmits<{
  (e: "updateScroll", value: string): void;
}>();
const route = useRoute();
const router = useRouter();
let list = [
  { title: "首页", type: 1, route: "home", path: "/home" },
  { title: "解决方案", type: 2, route: "scheme", path: "/scheme" },
  { title: "背调流程", type: 3, route: "flowPath", path: "/flowPath" },
  { title: "背调内容", type: 4, route: "surveyDetails", path: "/surveyDetails" },
  { title: "API服务", type: 5, route: "apiServe", path: "/apiServe" },
  { title: "竞业管理", type: 6, route: "competitionAgree", path: "/competitionAgree" },
  { title: "关于我们", type: 7, route: "aboutUs", path: "/aboutUs" },
];
// 路由跳转
const toRoute = (key) => {
  for (const i in list) {
    let item = list[i];
    if (key == item.type) {
      router.push(item.path);
      if (item.route == route.name) {
        return;
      }
      setTimeout(() => {
        nextTick(() => {
          window.scrollTo({
            top: 0,
          });
          emit("updateScroll", "1");
        });
      }, 80);
    }
  }
};
</script>
<style lang="scss" scoped>
.iconfont:before {
  margin-right: 5px;
}
.footer {
  color: #fff;
  width: 100%;
  .content {
    margin: 0 auto;
    width: 900px;
    height: 300px;
    padding: 25px 0;
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      .pointer:hover {
        color: #5887ef;
      }
    }
    .text {
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin-top: 50px;
      font-size: 14px;
      .cont {
        line-height: 2;
      }
      .code_img {
        img {
          width: 70px;
        }
      }
      .iconfont {
        font-size: 14px !important;
      }
    }
  }
}
</style>